import {useMediaQuery} from '@mui/material'

export type MediaWhenProps<T> = {
  onSuperSmall: () => T
  onSmall: () => T
  onMedium: () => T
  onBig: () => T
}

export class MediaSize {

  readonly isSuperSmall: boolean
  readonly isSmall: boolean
  readonly isMedium: boolean
  readonly isBig: boolean

  constructor(isSuperSmall: boolean, isSmall: boolean, isMedium: boolean) {
    this.isSuperSmall = isSuperSmall
    this.isSmall = isSmall
    this.isMedium = isMedium
    this.isBig = !isSmall && !isMedium
  }

  when<T>(
    {
      onSuperSmall,
      onSmall,
      onMedium,
      onBig,
    }: MediaWhenProps<T>,
  ): T {
    if (this.isSuperSmall) {
      return onSuperSmall()
    }
    if (this.isSmall) {
      return onSmall()
    }
    if (this.isMedium) {
      return onMedium()
    }
    return onBig()
  }
}

export const useMedia = () => {
  const isSuperSmall = !useMediaQuery('(min-width: 450px)')
  const isSmall = !useMediaQuery('(min-width:850px)')
  const isMedium = !useMediaQuery('(min-width:1100px)')
  return new MediaSize(isSuperSmall, isSmall, isMedium)
}