import {atom} from 'nanostores'
import {MessageSeverity} from '../components/utils/MessageSnackbar'
import {ulid} from 'ulid'

export type ToastMessage = { id: string; message: string; severity: MessageSeverity }

export const MessageStore = atom<Array<ToastMessage>>([])

export const removeToastMessage = (message: ToastMessage) => {
  MessageStore.set(MessageStore.get().filter((m) => m.id !== message.id))
}

export const addToastMessage = (message: string, severity: MessageSeverity = MessageSeverity.info) => {
  MessageStore.set([...MessageStore.get(), {id: ulid().toLowerCase(), message, severity}])
}