import React from 'react'
import {useStore} from '@nanostores/react'
import {Alert, Snackbar} from '@mui/material'
import {MessageStore, removeToastMessage} from '../../stores/MessageStore'

export enum MessageSeverity {
  error = 'error',
  warning = 'warning',
  success = 'success',
  info = 'info',
}

export const MessageSnackbar = () => {
  const messages = useStore(MessageStore)
  return (<>
    {messages.map(m => (
      <Snackbar key={'snack-id-' + m.id}
                open={!!m}
                autoHideDuration={4000}
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                onClose={() => removeToastMessage(m)}>
        <Alert severity={m.severity}>
          {m.message}
        </Alert>
      </Snackbar>))}
  </>)
}
